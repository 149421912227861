import { Alert, AlertColor, AlertTitle, Box, Button, Checkbox, CircularProgress, FormControlLabel, Snackbar, TextField, Typography } from '@mui/material';
import React from 'react';
import MyAppBar from './myappbar';
import { invoke } from '@tauri-apps/api/tauri'
import _axios from '../axios';
import { url } from '../conf';
interface statetype {
    sending: boolean,
    fromErr: boolean;
    subErr: boolean;
    bodyErr: boolean;
    from: string;
    sub: string;
    body: string;
    snack: boolean;
    snackType: AlertColor | undefined;
    snackMsg: string;
    si: string;
    ei: string;
    siErr: boolean;
    eiErr: boolean;
    attachment: File | null;
    checked: boolean
}
class Home extends React.Component {
    state: statetype = {
        sending: true,
        fromErr: false, subErr: false, bodyErr: false, from: "", sub: "", body: "",
        snack: false, snackMsg: "LookThius is a snack", snackType: 'success', si: "0", ei: "500", siErr: false, eiErr: false, attachment: null, checked: false
    }
    checkboxHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        // alert(event.target.value);
        if ('__TAURI__' in window) {
            this.setState({ checked: !this.state.checked })
        } else {
            this.setState({ snack: true, snackType: 'error', snackMsg: "Can only use this option on the App version", sending: false });

        }
    }
     get_file_array = (file:File) => {
        return new Promise<ArrayBuffer>((acc, err) => {
            const reader = new FileReader();
            reader.onload = (event) => { 
                if(event!=null && event.target!=null && event.target.result!=null && typeof(event.target.result)!='string'){
                    acc(event.target.result) };
                }
            reader.onerror = (er) => { err(er) };
            reader.readAsArrayBuffer(file);
        });
    }
    sendMails = async () => {
        this.setState({
            fromErr: false, subErr: false, bodyErr: false, eiErr: false, siErr: false
        })
        let { from, sub, body, ei, si, attachment, checked } = this.state;

        // checked=true;

        if (body == "")
            this.setState({ bodyErr: true })
        if (si == null)
            this.setState({ siErr: true })
        if (ei == null)
            this.setState({ eiErr: true })
        if (body != "" && si != null && ei != null) {
            try {
                let fd = new FormData();
                fd.append('from', from);
                fd.append('sub', sub);
                fd.append('body', body);
                fd.append('ei', ei);
                fd.append('si', si);
                fd.append('wasm', checked ? "1" : "0");
                if (attachment != null)
                    fd.append('attachment', attachment);
                this.setState({ sending: true });
                console.log(fd);
                let result = await _axios.post("/queueMail", fd);
                if (result.data.status) {
                    this.setState({ snack: true, snackType: 'success', snackMsg:"Queued successfully", sending: false });
                    if (result.data.wasm) {
                        console.log(result.data)
                        let { taskId, to, ms, subs } = result.data;
                        let s = [""];
                        if (sub != "") {
                            s = [sub];
                        } else {
                            s = subs.map((m:any)=>m.subject);
                        }
                        let filename = "", filetype = "", att = null;
                        if (attachment != null) {
                            filename = attachment.name;
                            filetype = attachment.type;
                            const temp = await attachment.arrayBuffer();
                            console.log('here we finally ve the file as a ArrayBuffer : ', temp);
                            att= new Uint8Array(temp)
                            att=Array.from(att);
                        }
                        // console.log('att',att);
                        invoke('rust_send_mails', {
                            to, taskid: taskId, ms, sub: s, body, filename, filetype, authcode: localStorage.getItem('authToken'), uri: url,attachment:att
                        }).then((res)=>{
                            this.setState({ snack: true, snackType: 'success', snackMsg:res, sending: false });
                        }).catch(err=>{
                            this.setState({ snack: true, snackType: 'error', snackMsg:err, sending: false });
                        })
                    }
                } else {
                    this.setState({ snack: true, snackType: 'error', snackMsg: result.data.msg, sending: false });
                }
            }
            catch (err: any) {
                this.setState({ snack: true, snackType: 'error', snackMsg: err.message, sending: false });
            }

        }

    }
    setSnack = (bool: boolean) => {
        this.setState({ snack: bool });
    }
    componentDidMount(): void {
        this.setState({ sending: false });
        if ('__TAURI__' in window) {
            this.setState({ checked: !this.state.checked })
        }
    }
    handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log(event)
        if (event.target != null && event.target.files != null && event.target.files.length != 0)
            this.setState({ attachment: event.target.files[0] })
    }
    render(): React.ReactNode {
        let { sending, fromErr, subErr, bodyErr, from, sub, body, snack, snackMsg, snackType, si, ei, siErr, eiErr } = this.state;
        return (<>
            <MyAppBar />
            <Snackbar open={snack} autoHideDuration={6000} onClose={() => this.setSnack(false)}>
                <Alert onClose={() => this.setSnack(false)} severity={snackType} sx={{ width: '100%' }} elevation={6} variant="filled" >
                    {snackMsg}
                </Alert>
            </Snackbar>
            <Box component="form"
                sx={{
                    '& .MuiTextField-root': { m: 2, width: '35ch' }
                }}
                noValidate
                autoComplete="off">
                {/* <Grid container spacing={1}>
                    <Grid item xs={4}>
                        &nbsp;
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>
                            <Alert style={{ fontSize: 16, textAlign: 'center' }}>
                                Enter details to send Bulk Mail <p style={{ padding: 0, margin: 0, fontSize: 8 }}>Mail wil be sent to all contacts</p></Alert>
                        </Typography>
                    </Grid>
                </Grid> */}
                <Typography>
                    <Alert sx={{ width: '90%', textAlign: 'left' }} >
                        <AlertTitle>Enter details to send Bulk Mail</AlertTitle>Mail wil be sent to all contacts — <strong>check it out!</strong></Alert>
                </Typography>

                <div>
                    <FormControlLabel
                        control={<Checkbox value="f" name="frontend" color="primary" onChange={this.checkboxHandle} checked={this.state.checked} />}
                        label="Send Using Frontend"
                    />
                    <div>
                        {/* <TextField
                            error={fromErr}
                            label="Proxy(Optional)"
                            placeholder='socks4://10.10.12.13:5647'
                            value={from}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({ from: event.target.value });
                            }}
                        /> */}

                        <TextField
                            error={subErr}
                            label="Subject(Optional)"
                            value={sub}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({ sub: event.target.value });
                            }}
                        />
                        <TextField
                            label="Attachment"
                            type="file"
                            variant='standard'
                            onChange={this.handleFileSelect}
                        />
                    </div>
                    <div>
                        <TextField
                            error={siErr}
                            label="Start Index"
                            placeholder='0'
                            type="number"
                            value={si}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({ si: event.target.value });
                            }}
                        />
                        <TextField
                            error={eiErr}
                            label="End Index"
                            value={ei}
                            type="number"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({ ei: event.target.value });
                            }}
                        />
                    </div>
                    <div>

                    </div>
                </div>

                <TextField fullWidth
                    style={{ width: 700, maxWidth: '87%' }}
                    error={bodyErr}
                    label="Mail Body"
                    placeholder="Dear {Name} is automatically added"
                    multiline={true}
                    rows={8}
                    value={body}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        this.setState({ body: event.target.value });
                    }}
                />
                <div>
                    <div><Button color='primary' variant='outlined' style={{ padding: 5, margin: 5 }} onClick={this.sendMails} disabled={sending}>{sending ? <CircularProgress color="inherit" size={28} /> : "Send!"}</Button></div>

                </div>
            </Box>
        </>
        )
    }
}
export default Home;