import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Alert, AlertColor, CircularProgress, Snackbar } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import _axios from '../axios';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://xynes.com/">
        Xynes
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();
interface loginProps{
    setAuth:(auth:boolean)=>void
}
export default function Login(props:loginProps) {
    const TOKEN='authToken';
    const [loading,setLoading]=React.useState(false);
    const [snack,setSnack]=React.useState(false);
    const [snackMsg,setSnackMsg]=React.useState("");
    const [snackType,setSnackType]=React.useState<AlertColor | undefined>('success');
    const showSnack = (show: boolean, type: AlertColor | undefined, msg: string) => {
        setSnack(show);
        setSnackMsg(msg);
        setSnackType(type);
    }
  const handleSubmit =async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // console.log({u:data.get('email'),r:data.get('rem')});
    
    try {
        let resp=await _axios.post("/login",data);
        setLoading(false);
        if(resp.data.status){
            let token=resp.data.token;
            console.log(token);
            localStorage.setItem(TOKEN,token);
            props.setAuth(true);
        }else{
            showSnack(true,'error',resp.data.msg);
        }
    } catch (error:any) {
        
        setLoading(false);
        showSnack(true,'error',error.message);
    }
    
  };

  return (
    <ThemeProvider theme={theme}>
        <Snackbar open={snack} autoHideDuration={6000} onClose={() => setSnack(false)}>
                <Alert onClose={() => setSnack(false)} severity={snackType} sx={{ width: '100%' }} elevation={6} variant="filled" >
                    {snackMsg}
                </Alert>
            </Snackbar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#fe7f9c' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Bulk Mail
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" name="rem" color="primary" />}
              label="Remember me" 
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress color="inherit" size={28} /> : "Sign In"}
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
