import { AlertColor, Button, CircularProgress, Modal, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import _axios from '../../axios';
import { url } from '../../conf';
type subjectProps = {
    open: boolean;
    handleClose: (bool: boolean) => void;
    snack: (show: boolean, type: AlertColor | undefined, msg: String) => void;
}
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '85%',
    minWidth: '60%',
    minHeight: '50%',
    maxWidth: '65%',
    overflow: 'scroll'
};
type skelProps = {
    animation?: 'pulse' | 'wave' | false;
}
const Skel = (props: skelProps) => {
    return (
        <>
            <Skeleton animation={props.animation} />
            <Skeleton animation={props.animation} />
            <Skeleton animation={props.animation} />
            <Skeleton animation={props.animation} />
        </>
    )
}
interface subjectListType {
    subject: String;
}

interface EListProps {
    list: subjectListType[] | [];
}
const EList = (props: EListProps) => {
    const rows = props.list.map((d: subjectListType) => {
        return {
            subject: d.subject,
        }
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <>
            <TableContainer component={Paper} >
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>No.</TableCell>
                            <TableCell>Subect</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, i: React.Key) => (
                                <TableRow
                                    key={i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {(Number(i) + 1) + (page * rowsPerPage)}
                                    </TableCell>
                                    <TableCell >{row.subject}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}
const SubjectList = (props: subjectProps) => {
    const [uploading, setUploading] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [emailList, setSubjectList] = useState<subjectListType[]>([]);
    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
    const [pickerErr, setPickerErr] = useState(false);
    useEffect(() => {
        // let tmp: emailListType[] = [];
        // for (let i = 1; i < 100; i++) {
        //     tmp = [...tmp, ...[
        //         { name: 'Aman', email: 'aman@xynes.com' },
        //         { name: 'Ray', email: 'Ray@xynes.com' }
        //     ]]
        // }
        // setEmailList(tmp);
        fetchList();
    }, []);
    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target != null && event.target.files != null && event.target.files.length != 0)
            setSelectedFile(event.target.files[0])
    }

    const fetchList = async () => {
        setLoading(true);
        try{
        const r=await _axios.get("/subjectList");
        setLoading(false);
        if(r.data.status){
            setSubjectList(r.data.list);
        }
        }catch(e:any){
            setLoading(false);
            props.snack(true, 'error', e.message);
        }

    }
    const uploadCsv =async () => {
        setPickerErr(false);
        if (selectedFile == null)
            setPickerErr(true);
        if (selectedFile != null) {
            try {
                setUploading(true);
                let formData=new  FormData();
                formData.append('csv',selectedFile)
                const response = await _axios({
                    method: "post",
                    url:"/upSubCsv",
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                  });
                  setUploading(false);
                  if(response.data.status){
                    fetchList();
                    props.snack(true, 'success', response.data.msg);
                  }else{
                    props.snack(true, 'error', response.data.msg);
                  }
            } catch (error: any) {
                setUploading(false);
                props.snack(true, 'error', error.message);
            }
        }
        
    }
    return (
        <Modal
            open={props.open}
            onClose={() => props.handleClose(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Upload CSV to Add to Subject List:
                    <p style={{ fontSize: 7, margin: 0, padding: 0 }}>(New CSV will replace old list and not append to it)</p>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>

                            <TextField
                                error={pickerErr}
                                type="file"
                                variant='standard'
                                onChange={handleFileSelect}
                            />
                            <Button variant='outlined' style={{ padding: 5, margin: 5 }} onClick={() => uploadCsv()} disabled={uploading}>{uploading ? <CircularProgress color="inherit" size={28} /> : "Upload"}</Button>
                        </div>


                        <hr />
                        <div>
                            {
                                loading ? <Skel animation="wave" /> : emailList.length === 0 ? <p>No Subjects found</p> : <EList list={emailList} />
                            }
                        </div>
                        <div>
                            <Button color='error' variant='outlined' style={{ padding: 5, margin: 5 }} onClick={() => props.handleClose(false)}>Close</Button>
                           
                        </div>
                    </Box>
                </Typography>
            </Box>
        </Modal>
    )
}
export default SubjectList;