import axios, { AxiosRequestConfig } from "axios";
import { url } from "./conf";

const _axios = axios.create({
    baseURL: url,
    timeout: 50000,
})
_axios.interceptors.response.use(undefined, (error) => {
    if (error.response && error.response.status === 401) {
        error.message = "Security Token Expired, Please logout! and login Again";
        // localStorage.removeItem('token');
        // window.location.reload();
        return Promise.reject(error);
    } else if (error.response && error.response.status === 500) {
        error.message = "Failed to Retrive account details";
        // localStorage.removeItem('token');
        // window.location.reload();
        return Promise.reject(error);
    } else {
        return Promise.reject(error);
    }
});
// (parameter) config: AxiosRequestConfig<any>
_axios.interceptors.request.use((config: AxiosRequestConfig<any>) => {
    let token = localStorage.getItem("authToken");
    if (config && config.headers && token!=null)
        config.headers["Authorization"] = "Bearer " + token;
    return config;
})
export default _axios;