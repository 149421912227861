import './App.css';
import Home from './Components/home';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import red from '@mui/material/colors/blue';
import { useEffect, useState } from 'react';
import Login from './Components/login';
import { invoke } from '@tauri-apps/api/tauri'
import { Button } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: red[500],
    }
  },
});
const inv=()=>{
  invoke('my_custom_command', { invokeMessage: 'Hello hsdcgyujdgcjydgvdg! Fuck yeah',ser:[{
    name:"Aman",
    email:"aman.co.cc@g.b"

  }] })
}
function App() {
  const [auth,setAuth]=useState(false);
  useEffect(()=>{
    if(localStorage.getItem('authToken')!=null){
      setAuth(true);
    }
    
  },[])
  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      {auth? <Home/>: <Login setAuth={setAuth}/>}
    </div>
    </ThemeProvider>
  );
}

export default App;
