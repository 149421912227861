import { Alert, AlertColor, AlertTitle, Modal, Tab, Tabs, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import _axios from '../../axios';
import CircularProgressWithLabel from './CircularProgressWithLabel';
type serverProps = {
    open: boolean;
    handleClose: (bool: boolean) => void;
    snack: (show: boolean, type: AlertColor | undefined, msg: String) => void
}
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '85%',
    width: 600,
    maxWidth: '75%',
    overflow: 'scroll'
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
interface Task {
    _id: string;
    userId: string;
    name: string;
    percentage: number;
    status: number;
    type: string;
    count: number;
    total: number;
    msg: string;
    finishedOn: Date;
    addedOn: Date;
}
let cTaskCount = 0;
let pollCount = 0;
const Dashboard = (props: serverProps) => {
    const [ct, setCt] = useState<Task[]>([]);
    const [ht, setHt] = useState<Task[]>([]);
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const loadCurrentTask = async (tasks?: Array<Task>) => {
        try {

            let postdata = {
                tasks
            };
            let resp = await _axios.post("/ctask", postdata);
            pollCount++;
            if (resp.data.status) {
                setCt(resp.data.tasks);
                if (pollCount != 1) {
                    if (cTaskCount != resp.data.tasks.length) {
                        props.snack(true, 'info', 'A Task has been added or completed');
                        loadPreviousTask();
                    }
                }
                cTaskCount = resp.data.tasks.length;
                loadCurrentTask(resp.data.tasks);

            } else {
                if (cTaskCount != 0) {
                    cTaskCount = 0;
                    props.snack(true, 'info', 'A Task has been added or completed');
                    loadPreviousTask();
                }
                setCt([]);
                loadCurrentTask();
            }
        } catch (err: any) {
            // props.snack(true, "error", err.message);
            setCt([]);
            loadCurrentTask();
        }

    }
    const loadPreviousTask = async () => {
        try {

            let resp = await _axios.get("/hist");
            if (resp.data.status) {
                setHt(resp.data.tasks);
            } else {
            }
        } catch (err: any) {
            props.snack(true, "error", err.message);
        }
    }
    useEffect(() => {
        loadCurrentTask();
        loadPreviousTask();
    }, [])

    return (
        <Modal
            open={props.open}
            onClose={() => props.handleClose(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <Alert sx={{ width: '90%', textAlign: 'left' }} >
                        <AlertTitle> Here you can find the list of Tasks</AlertTitle>
                        {/* If you are not seeing a task — <strong>Please refresh the page!</strong> */}
                    </Alert>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs indicatorColor='secondary' variant='fullWidth' value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Current Task" {...a11yProps(0)} />
                                <Tab label="History" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                {ct == null || ct.length == 0 ? <h3>No Task Running</h3> : ct.map(c => <><ListItem>
                                    <ListItemAvatar style={{ padding: 20 }}>

                                        <CircularProgressWithLabel value={c.percentage} size={70} />

                                    </ListItemAvatar>
                                    <ListItemText primary={c.msg + " Task = " + c.type} secondary={`${c.addedOn}\t| ${c.count} Processed out of ${c.total}`} />
                                </ListItem> <hr /></>)}

                            </List>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {ht == null || ht.length == 0 ? <h3>No Previous Task Running</h3> : ht.map(c => <><ListItem>
                                <ListItemAvatar style={{ padding: 20 }}>

                                    <CircularProgressWithLabel value={c.percentage} color={c.percentage == 100 ? 'success' : 'error'} size={70} />

                                </ListItemAvatar>
                                <ListItemText primary={c.msg} secondary={`${c.addedOn}\t| ${c.count} Processed out of ${c.total}`} />
                            </ListItem><hr /></>)}
                        </TabPanel>

                    </Box>
                </Typography>
            </Box>
        </Modal>
    )
}
export default Dashboard;