import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/MenuOpen';
import { Alert, AlertColor, Snackbar } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import _axios from '../axios';
import Dashboard from './Modals/Dashboard';
import MailingList from './Modals/MailingList';
import Server from './Modals/Server';
import SubjectList from './Modals/Subject';
const pages = ['Mail Server', 'Mailing List','Subjects'];
const settings = ['Account', 'Dashboard', 'Logout'];

const MyAppBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<HTMLButtonElement | null>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<HTMLButtonElement | null>(null);
    const [serverModal, setServerModal] = React.useState<boolean>(false);
    const [listModal, setListModal] = React.useState<boolean>(false);
    const [subjectModal,setSubjectModal] =React.useState<boolean>(false);
    const [successSnack, setSuccessSnack] = React.useState<boolean>(false);
    const [snackType, setSnackType] = React.useState<AlertColor | undefined>("success");
    const [snackMsg, setSnackMsg] = React.useState<String>("This is a Test Snack");
    const [dialogTitle,setDialogTitle]=React.useState("Attention");
    const [dialogDesc,setDialogDesc]=React.useState("");
    const [dialogOpen,setDilaogOpen]=React.useState(false);
    const [debouncing,setDeboucing]=React.useState(false);
    const [dashboardOpen,setDashboardOpen]=React.useState(false);
    const showSnack = (show: boolean, type: AlertColor | undefined, msg: String) => {
        setSuccessSnack(show);
        setSnackMsg(msg);
        setSnackType(type);
    }
    const handleCloseDialog=()=>{
        setDilaogOpen(false);
    }
    const showDialog=(dialogTitle:string,dialogDesc:string,dialogOpen:boolean)=>{
        setDialogDesc(dialogDesc);
        setDialogTitle(dialogTitle);
        setDilaogOpen(dialogOpen);
    }
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const debounceCheckConfirm=()=>{
        showDialog("Are you Sure","Dear User, Debouce checking is a long process and might take an hour to complete. Are you want to continue?",true);
    }
    const handleCloseNavMenu = (page: string) => {

        if (page === 'Mail Server') {
            setServerModal(true);
        } else if (page == 'Mailing List') {
            setListModal(true);
        }else if(page== 'Subjects'){
            setSubjectModal(true)
        }
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = (setting:string) => {
        if(setting=="Dashboard"){
            // alert(setting);
            setDashboardOpen(true);
        }else if(setting=="Logout"){
            localStorage.removeItem("authToken");
            window.location.reload();
        }
        setAnchorElUser(null);
    };
    const debounceCheck=async ()=>{
        try {
            setDilaogOpen(false);
            setDeboucing(true);
            let resp=await _axios.post("/debounce");
            setDeboucing(false);
            if(resp.data.status){
                showSnack(true,'success',resp.data.msg);
            }else{
                showSnack(true,'error',resp.data.msg);
            }
        } catch (error) {
            setDeboucing(false)
        }
    }

    return (
        <AppBar position="static" style={{ backgroundColor: '#fe7f9c' }}>
            <Container maxWidth="xl">
                <Dialog
                    open={dialogOpen}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {dialogTitle}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{dialogDesc}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Exit</Button>
                        <Button onClick={debounceCheck} autoFocus>
                            Proceed
                        </Button>
                    </DialogActions>
                </Dialog>
                <Server open={serverModal} handleClose={(bool: boolean) => setServerModal(bool)} snack={showSnack} />
                <MailingList open={listModal} debouncing={debouncing} debounceCheckConfirm={debounceCheckConfirm} handleClose={(bool: boolean) => setListModal(bool)} snack={showSnack} />
                <SubjectList open={subjectModal} handleClose={(bool: boolean) => setSubjectModal(bool)} snack={showSnack} />
                <Snackbar open={successSnack} autoHideDuration={6000} onClose={() => setSuccessSnack(false)}>
                    <Alert onClose={() => setSuccessSnack(false)} severity={snackType} sx={{ width: '100%' }} elevation={6} variant="filled" >
                        {snackMsg}
                    </Alert>
                </Snackbar>
                <Dashboard open={dashboardOpen} handleClose={(bool: boolean) => setDashboardOpen(bool)} snack={showSnack}/>
                <Toolbar disableGutters>
                    <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        BULK <p style={{ fontSize: 8, marginTop: 13 }}>Mail</p>
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={() => handleCloseNavMenu(page)}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        BULK <p style={{ fontSize: 6, marginTop: 18 }}>Mail</p>
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={() => handleCloseNavMenu(page)}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="User" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={()=>handleCloseUserMenu(setting)}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default MyAppBar;
